import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import styled from "styled-components";
import useLanguage from '../hooks/uselanguage';

// npm i @emailjs/browser

const Contact = () => {
  const form = useRef();

  var okmsg = "Message sent successfully !";
  var komsg = "Error sending message !";

  if( useLanguage().language === 'pt' ) {
      okmsg = "Mensagem enviada com sucesso !";
      komsg = "Erro ao enviar mensagem !";
  }

  const sendEmail = (e) => {
    e.preventDefault();
    
    // alert(okmsg);
    // form.current.reset();
    // return;

    emailjs
      .sendForm(
        "service_l84v9md",
        "template_apbmmgi",
        form.current,
        {
            publicKey: 'fMwBKKIJqR8mjAt8Z',
        }
      )
      .then(
        (result) => {
          alert(okmsg);
          console.log("message sent");
          form.current.reset();
        },
        (error) => {    
            alert(komsg);
            console.log("ERRO : ",error.text);
        }
      );
  };

  return (
    <StyledContactForm>
      <form ref={form} onSubmit={sendEmail}>
        <label>{(useLanguage().language === 'pt')?"Nome":"Name"}</label>
        <input type="text" name="user_name" />
        <label>Email</label>
        <input type="email" name="user_email" />
        <label>{(useLanguage().language === 'pt')?"Mensagem":"Message"}</label>
        <textarea name="message" />
        <input type="submit" value={(useLanguage().language === 'pt')?"Enviar":"Send"} />
      </form>
    </StyledContactForm>
  );
};

export default Contact;

// Styles
const StyledContactForm = styled.div`
  width: 100%;
  padding-left: 0em;
  padding-right: 0em;

  form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-size: 1em;
    font-family: 'AW-Text';

    input {
      width: 100%;
      height: 35px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);

      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }

    textarea {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      max-height: 100px;
      min-height: 100px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);

      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }

    label {
      margin-top: 1rem;
    }

    input[type="submit"] {
      margin-top: 1rem;
      cursor: pointer;
      background: #a89782aa;
      color: white;
      border: none;
      font-size: 1.2em;
    }
  }
`;